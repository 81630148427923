$breakpoint_mobile: 767px;
$breakpoint_tablet: 991px;
$breakpoint_small_monitor: 1199px;

$width_mobile: auto;
$width_tablet: 723px;
$width_small_monitor: 933px;
$width_large_monitor: 1127px;

$margin_mobile: 1em;
$margin_tablet: auto;
$margin_small_monitor: auto;
$margin_large_monitor: auto;

.container {
  display: block;
  max-width: 100% !important;

  @media screen and (max-width: $breakpoint_mobile) {
    width: $width_mobile;
    margin: 0 $margin_mobile;
  }

  @media screen and (min-width: ($breakpoint_mobile + 1px)) and (max-width: $breakpoint_tablet) {
    width: $width_tablet;
    margin: 0 $margin_tablet;
  }

  @media screen and (min-width: ($breakpoint_tablet + 1px)) and (max-width: $breakpoint_small_monitor) {
    width: $width_small_monitor;
    margin: 0 $margin_small_monitor;
  }

  @media screen and (min-width: ($breakpoint_small_monitor + 1px)) {
    width: $width_large_monitor;
    margin: 0 $margin_large_monitor;
  }
}
